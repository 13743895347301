
export default {
    multicallAddress: {
        1: '0x1156256625faa48840Aea2Cd6f96418FDB4370fB',
        56: '0x90e10e1e4f4a6a568344bae21099d7ec810b01a7',
        97: '0x6715dfab3ef53834584098c020e21cf283ae1706',
        137: '0x90e10e1e4f4a6a568344bae21099d7ec810b01a7',
        8453: '0x8b01b43d1C02Df8529987268C6412D41406b542b',
        42161: '0x0000000000000000000000000000000000000000',
        43114: '0x0000000000000000000000000000000000000000',
    },
    factoryAddress: {
        1: '0xE1C7759AebCd688e5f23B7C7A96a2dB87582A9Ae',
        // 56: '0x528aCb70DC0c0355286570164fd36C7E8C413698',
        // 56: '0xfd03f51fb491bd33a615a601c6b0f6adfbcedaa0',
        56: '0x9f23C2D45af9B297257A3e574E1271A4a66f8113',
        97: '0x2d0b91ba337a6a85816d83446a3effc3bdb474cb',
        137: '0xE1C7759AebCd688e5f23B7C7A96a2dB87582A9Ae',
        8453: '0xCb0429bD8EbE2da227070766F98be42d4cc85B28',
        42161: '0x0000000000000000000000000000000000000000',
        43114: '0x0000000000000000000000000000000000000000',
    },
    contractAddress: {
        1: '0x0000000000000000000000000000000000000000',
        56: '0x0000000000000000000000000000000000000000',
        97: '0x0000000000000000000000000000000000000000',
        137: '0x0000000000000000000000000000000000000000',
        8453: '0x0000000000000000000000000000000000000000',
        42161: '0x0000000000000000000000000000000000000000',
        43114: '0x0000000000000000000000000000000000000000',
    },
    WETHAddress: {
        1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
        137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        8453: '0x4200000000000000000000000000000000000006',
        42161: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
        43114: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    },
    routerAddress: {
        1: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
        56: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
        97: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
        137: '0xedf6066a2b290C185783862C7F4776A2C8077AD1',
        8453: '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24',
        42161: '0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24', // arbitrum
        43114: '0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24', // avax
    },
    defaultAddress: {
        1: '0x0000000000000000000000000000000000000000',
        56: '0x0000000000000000000000000000000000000000',
        97: '0x0000000000000000000000000000000000000000',
        137: '0x0000000000000000000000000000000000000000',
        8453: '0x0000000000000000000000000000000000000000',
        42161: '0x0000000000000000000000000000000000000000',
        43114: '0x0000000000000000000000000000000000000000',
    },
}