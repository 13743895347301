export default function ConnectButton() {
    return <w3m-button />
  }

// import { useAccount } from 'wagmi';

// import { Account } from './Account.tsx';
// import { Connect } from './Connect.tsx';

// export function ConnectButton() {
//   const { isConnected } = useAccount();
//   return (
//     <div className="container">{isConnected ? <Account /> : <Connect />}</div>
//   );
// }
